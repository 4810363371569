/* Class names prefixes */
/* Colors / Theme */
.gjs-clm-tags .gjs-sm-title,
.gjs-sm-sector .gjs-sm-title {
  border-top: none; }

.gjs-clm-tags .gjs-clm-tag {
  background-color: #4c9790;
  border: none;
  box-shadow: none;
  padding: 5px 8px;
  text-shadow: none; }

.gjs-field {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: none; }

.gjs-btnt.gjs-pn-active,
.gjs-pn-btn.gjs-pn-active {
  box-shadow: none; }

.gjs-pn-btn:hover {
  color: rgba(255, 255, 255, 0.75); }

.gjs-btnt.gjs-pn-active,
.gjs-color-active,
.gjs-pn-btn.gjs-pn-active,
.gjs-pn-btn:active,
.gjs-block:hover {
  color: #35d7bb; }

#gjs-rte-toolbar .gjs-rte-btn,
.gjs-btn-prim,
.gjs-btnt,
.gjs-clm-tags .gjs-sm-composite.gjs-clm-field,
.gjs-clm-tags .gjs-sm-field.gjs-sm-composite,
.gjs-clm-tags .gjs-sm-stack #gjs-sm-add,
.gjs-color-main,
.gjs-mdl-dialog,
.gjs-off-prv,
.gjs-pn-btn,
.gjs-pn-panel,
.gjs-sm-sector .gjs-sm-composite.gjs-clm-field,
.gjs-sm-sector .gjs-sm-field.gjs-sm-composite,
.gjs-sm-sector .gjs-sm-stack #gjs-sm-add {
  color: #a0aabf; }

#gjs-rte-toolbar,
.gjs-bg-main,
.gjs-clm-select option,
.gjs-clm-tags .gjs-sm-colorp-c,
.gjs-editor,
.gjs-mdl-dialog,
.gjs-nv-item .gjs-nv-title-c,
.gjs-off-prv,
.gjs-pn-panel,
.gjs-block,
.gjs-select option,
.gjs-sm-sector .gjs-sm-colorp-c,
.gjs-sm-select option,
.gjs-sm-unit option,
.sp-container {
  background-color: #373d49; }

.gjs-import-label,
.gjs-export-label {
  margin-bottom: 10px;
  font-size: 13px; }

.gjs-mdl-dialog .gjs-btn-import {
  margin-top: 10px; }

.CodeMirror {
  border-radius: 3px;
  height: 450px;
  font-family: sans-serif, monospace;
  letter-spacing: 0.3px;
  font-size: 12px; }

/* Extra */
.gjs-block {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 10px 2.5% 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow, color 0.2s ease 0s; }

.gjs-block:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15); }

#gjs-pn-views-container.gjs-pn-panel {
  padding: 39px 0 0; }

#gjs-pn-views.gjs-pn-panel {
  padding: 0;
  border: none; }

#gjs-pn-views .gjs-pn-btn {
  margin: 0;
  height: 40px;
  padding: 10px;
  width: 25%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3); }

#gjs-pn-views .gjs-pn-active {
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 2px solid #35d7bb;
  border-radius: 0; }

#gjs-pn-devices-c {
  padding-left: 30px; }

#gjs-pn-options {
  padding-right: 30px; }

.gjs-sm-composite .gjs-sm-properties {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

#gjs-sm-border-top-left-radius,
#gjs-sm-border-top-right-radius,
#gjs-sm-border-bottom-left-radius,
#gjs-sm-border-bottom-right-radius,
#gjs-sm-margin-top,
#gjs-sm-margin-bottom,
#gjs-sm-margin-right,
#gjs-sm-margin-left,
#gjs-sm-padding-top,
#gjs-sm-padding-bottom,
#gjs-sm-padding-right,
#gjs-sm-padding-left {
  flex: 999 1 60px; }

#gjs-sm-border-width,
#gjs-sm-border-style,
#gjs-sm-border-color {
  flex: 999 1 80px; }

#gjs-sm-margin-left,
#gjs-sm-padding-left {
  order: 2; }

#gjs-sm-margin-right,
#gjs-sm-padding-right {
  order: 3; }

#gjs-sm-margin-bottom,
#gjs-sm-padding-bottom {
  order: 4; }

.gjs-field-radio {
  width: 100%; }

.gjs-field-radio #gjs-sm-input-holder {
  display: flex; }

.gjs-radio-item {
  flex: 1 0 auto;
  text-align: center; }

.gjs-sm-sector .gjs-sm-property.gjs-sm-list {
  width: 50%; }

.gjs-mdl-content {
  border-top: none; }

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer.gjs-sm-active {
  background-color: rgba(255, 255, 255, 0.09); }

/*

#gjs-pn-views-container,
#gjs-pn-views{
  min-width: 270px;
}
*/
.gjs-f-button::before {
  content: 'B'; }

.gjs-f-divider::before {
  content: 'D'; }

.gjs-mdl-dialog-sm {
  width: 300px; }

.gjs-mdl-dialog form .gjs-sm-property {
  font-size: 12px;
  margin-bottom: 15px; }

.gjs-mdl-dialog form .gjs-sm-label {
  margin-bottom: 5px; }

#gjs-clm-status-c {
  display: none; }

.anim-spin {
  animation: 0.5s linear 0s normal none infinite running spin; }

.form-status {
  float: right;
  font-size: 14px; }

.text-danger {
  color: #f92929; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
